@charset "UTF-8";

@import url('https://fonts.googleapis.com/css?family=EB+Garamond|Roboto:100,500');

$pc:      "screen and (min-width: 920px)";

$maxWidth: 1230px;

$gothic: sans-serif;
$mincho: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "Sawarabi Mincho", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
$garamond: 'EB Garamond', serif;
$roboto: 'Roboto', sans-serif;

$colorHead: black;
$colorText: #555;

html {
  font-size: 62.5% !important;
}

body {
  font-size: 1.4rem;
  line-height: 2.2rem;
  //opacity: 0; // fix to fademover
}


// common
a {
  text-decoration: underline;
  transition: opacity 0.3s linear;
}
a:hover {
  opacity: 0.7;
}
.contentsBlock {
  padding-left: 25px;
  padding-right: 25px;
}
.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  height: 0px;
  visibility: hidden;
}


// header
#header {
  position: fixed;
  z-index: 99;
  top: 0;
  width: 100%;
  height: 70px;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  //background: white;
  //box-shadow: 0 0 20px rgba(0,0,0,0.05);
  //border-bottom: solid 1px whitesmoke;
  h1.logo {
    flex-grow: 1;
    text-align: center;
    align-self: center;
  }
  /*.btnMenu {
    width: 80px;
    padding-left: 25px;
    align-self: center;*/
  .btnMenu {
    position: relative;
    width: 28px;
    height: 20px;
    margin-left: 25px;
    margin-right: 27px;
    align-self: center;
    span {
      position: absolute;
      display: inline-block;
      transition: all .4s;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: black;
      &:nth-of-type(1) {
        top: 0;
      }
      &:nth-of-type(2) {
        top: 9px;
      }
      &:nth-of-type(3) {
        bottom: 0;
      }
    }
    &.js-hiraku-offcanvas-btn-active {
      span:nth-of-type(1) {
        -webkit-transform: translateY(9px) rotate(-45deg);
        transform: translateY(9px) rotate(-45deg);
      }
      span:nth-of-type(2) {
        opacity: 0;
      }
      span:nth-of-type(3) {
        -webkit-transform: translateY(-9px) rotate(45deg);
        transform: translateY(-9px) rotate(45deg);
      }
    }
  }
  .btnEc {
    width: 80px;
    padding-right: 25px;
    align-self: center;
    text-align: right;
    svg,
    img {
      width: 28px;
      height: auto;
    }
    span {
      display: none;
    }
  }
  transition: transform ease-out 0.3s, margin-left .3s ease-in-out; // hiraku +
  &.scroll {
    transform: translateY(-80px);
  }
}
@media #{$pc} {
  #header {
    height: 90px;
    //transition: height ease 0.5s, margin-left .3s ease-in-out; // hiraku +
    h1.logo {
      svg, img {
        width: auto;
        height: 47px;
        transition: height ease 0.5s;
      }
    }
    .btnMenu {
      width: 36px;
      height: 26px;
      margin-left: 40px;
      margin-right: 144px;
      span {
        &:nth-of-type(2) {
          top: 12px;
        }
      }
      &.js-hiraku-offcanvas-btn-active {
        span:nth-of-type(1) {
          -webkit-transform: translateY(12px) rotate(-45deg);
          transform: translateY(12px) rotate(-45deg);
        }
        span:nth-of-type(3) {
          -webkit-transform: translateY(-12px) rotate(45deg);
          transform: translateY(-12px) rotate(45deg);
        }
      }
    }
    .btnEc {
      width: 220px;
      padding-right: 40px;
      img {
        width: 34px;
        height: auto;
      }
      span {
        display: inline-block;
        color: black;
        text-decoration: none;
        font-size: 1.2rem;
        font-family: $garamond;
        letter-spacing: 0.1em;
        margin-right: 0.5em;
      }
    }
    transition: transform ease-out 0.5s, margin-left .3s ease-in-out; // hiraku +
    &.scroll {
      transform: translateY(-150px);
      //height: 100px;
      //h1.logo {
      //  svg, img {
      //    height: 37px;
      //  }
      //}
    }
  }
}


// global navigation
#gNav {
  padding: 25px;
  div.btnMenuClose {
    margin-bottom: 15px;
  }
  ul {
    list-style: none;
    border-top: solid 1px silver;
    padding: 10px 0;
    li {
      font-size: 1.3rem;
      a {
        display: block;
        text-decoration: none;
        color: black;
        padding: 4px 0;
        &:focus {
          outline: 0;
        }
      }
      &.border {
        border-top: solid 1px silver;
      }
    }
    &:last-child {
      border-bottom: solid 1px silver;
    }
  }
  div.snsBtn {
    a {
      display: inline-block;
      font-size: 2rem;
      padding: 0 8px;
      margin-right: 8px;
      color: dimgray;
    }
  }
}


#supportLinks {
  @extend .contentsBlock;
  padding-top: 60px;
  padding-bottom: 60px;
  background: #111;
  color: white;
  h2 {
    font-size: 1.6rem;
    font-family: $mincho;
    font-weight: normal;
    letter-spacing: 0.2em;
    margin-bottom: 2em;
  }
  a {
    display: inline-block;
    color: white;
    text-decoration: none;
  }
  p {
    //color: $colorText;
    margin-bottom: 0.5em;
  }
  div.telephone {
    margin-bottom: 60px;
    > a {
      font-size: 3.4rem;
      font-family: $roboto;
      font-weight: 100;
      letter-spacing: 0.1em;
      margin-top: 0.7em;
      margin-bottom: 0.5em;
      i {
        margin-right: 5px;
      }
    }
    h2 {
      margin-bottom: 1em;
    }
    div.faq {
      padding: 0;
      font-size: 1.6rem;
      a {
        display: inline-block;
        padding: 0.5em 1em;
        background: white;
        color: black;
        i {
          margin-right: .5em;
        }
      }
    }
  }
  div.after {
    border-top: solid 1px dimgray;
    padding-top: 60px;
    ul {
      list-style: none;
      li {
        a {
          padding: 0.5em 0;
          i {
            margin-right: 0.5em;
          }
        }
      }
    }
  }
}
@media #{$pc} {
  #supportLinks {
    display: flex;
    justify-content: center;
    div {
      padding: 15px 40px;
    }
    div.telephone {
      margin-bottom: 0;
      > a {
        pointer-events: none;
      }
      div.faq {
        padding: 0;
      }
    }
    div.after {
      padding-top: 15px;
      border-top: none;
      border-left: solid 1px dimgray;
    }
  }
}


#footerLinks {
  @extend .contentsBlock;
  padding-top: 30px;
  padding-bottom: 30px;
  h2 {
    font-family: $garamond;
    font-size: 1.4rem;
    letter-spacing: 0.2em;
    margin-bottom: 1em;
    margin-top: 30px;
    i {
      margin-left: 0.5em;
      vertical-align: text-bottom;
      color: darkgray;
    }
  }
  ul {
    list-style: none;
    font-size: 1.3rem;
    display: none;
    li {
      line-height: 2.8em;
      a {
        text-decoration: none;
        color: $colorText;
      }
    }
  }
  div.continue {
    h2 {
      display: none;
    }
  }
}
@media #{$pc} {
  #footerLinks {
    display: flex;
    justify-content: center;
    h2 {
      i {
        display: none;
      }
    }
    ul {
      display: block;
    }
    div {
      padding: 0 40px;
    }
    div.continue {
      h2 {
        display: block;
        color: white;
      }
    }
  }
}


#snsLinks {
  @extend .contentsBlock;
  margin-bottom: 30px;
  ul {
    list-style: none;
    text-align: center;
    li {
      display: inline-block;
      font-size: 2.4rem;
      margin: 0 8px;
      a {
        color: dimgray;
      }
    }
  }
}


footer {
  text-align: center;
  padding: 20px;
  font-size: 1.2rem;
  color: $colorText;
  border-top: solid 1px whitesmoke;
  margin: 0 25px;
}


// hiraku.js override
.js-hiraku-offcanvas-body-left, .js-hiraku-offcanvas-body-right {
  overflow: visible;
}
.js-hiraku-offcanvas-body-left {
  overflow-y: visible;
}


// hiraku.js override pc only
@media #{$pc} {
  .js-hiraku-offcanvas-body-left .js-hiraku-header-fixed {
    margin-left: 250px;
  }
  .js-hiraku-offcanvas .js-hiraku-offcanvas-sidebar-left {
    margin-left: -250px;
  }
  .js-hiraku-offcanvas .js-hiraku-offcanvas-sidebar {
    width: 250px;
  }
  .js-hiraku-offcanvas-body-left {
    left: 250px;
  }
}


// desvg logo color change (top only)
div#header {
  &.white {
    background: none;
    box-shadow: none;
    div.btnMenu span {
      background-color: white;
    }
  }
}
